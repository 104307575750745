body {
  font-size: 1rem;
  font-family: 'Anek Odia', sans-serif;
  padding: 0px;
  margin: 0px;
}

#app-holder {
  display: flex;
  flex-direction: column;
}

#navbar {
  display: flex;
  justify-content: space-between;
  top: 0px;
  left: 0px;
  right:auto;
  bottom: auto;
  background-color: black;
  color: white;
  flex-grow: 0;
}

.navtitle {
  font-size: large;
  font-weight: 700;
  padding-top: 1rem;
  padding-left: 1rem;
}

#nav-menu {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-right: 1rem;
}

.menuIcon {
  font-size: 1.2rem;
  padding: 4px 8px;
}

.menuIcon a{
  color: white;
}

.menuIcon a:visited{
  color: white;
}

.segment {
	/* -webkit-box-shadow: 0 1px 2px 0 rgba(34,36,38,.15); */
	box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
	padding: 1em 1em;
	border-radius: .3rem;
	border: 1px solid rgba(34,36,38,.15);
}

.statHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
}

.statValue {
  font-size: 1.0rem;
  font-weight: 500;
}

.statTitle {
  font-size: 0.9rem;
}

#chartHolder {
  height: 60vh;
}


.titleHeader {
  padding: 20px 10px 4px 1rem;
  margin-bottom: 4px;
  font-size: large;
  font-weight: 700;
  border-bottom: solid #00000036 1px;
}

.date-selector {
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
}

.recordHeader {
  padding: 20px 10px 4px 1rem;
  margin-bottom: 4px;
  font-size: 1.0rem;
  font-weight: 700;
}

.recordStatHolder  {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px 2px 1rem;
}

.recordStatValue  {
  padding: 4px 8px;
}

.react-datepicker__input-container input {
  font-size: 0.9rem;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
  padding: .4em 0.5em;
}

.react-datepicker {
  font-family: inherit !important;
}
.login-form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    padding-top: 32px;
  }
  
  .login-form label {
    margin-bottom: 10px;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(34,36,38,.15);
  }
  
  .login-form button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }

  .login-form input, .login-form button {
    width: 100%;
    padding: 10px;
    box-sizing: border-box; /* Add this line */
  }
  
  .error-message {
    color: red;
  }

.logout-button {
    margin-left: 12px;
}